<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-narrow">
        <div class="card my-overflow">
          <header class="card-header">
            <p class="card-header-title has-text-grey">
              {{ $t('customer.shipping.months_review') }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <b-field :label="$t('customer.shipping.see_data_from_before')">
                <b-datepicker
                  v-model="selectedMonth"
                  :loading="isFetching"
                  type="month"
                  placeholder="Click to select..."
                  icon="calendar-alt"
                  @input="fetchShippings(selectedMonth)"
                />
              </b-field>
              <dl>
                <dt>
                  <template>{{ $t('customer.shipping.number_of_shipped_crates') }} :</template>
                  <strong>{{ total }}</strong>
                </dt>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <gridManager
          :grid-id="gridId"
          :profiles="gridProfiles"
          @save="saveProfile"
          @create="createProfile"
          @update="updateProfile"
          @select="selectProfile"
          @delete="deleteProfile"
        />

        <ag-grid-vue
          :id="gridId"
          style="width: 100%; height: 700px;"
          class="ag-theme-alpine"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-auto-page-size="true"
          :side-bar="['columns']"
          row-selection="multiple"
          :row-data="filteredEndusers"
          :multi-sort-key="'ctrl'"
          :enable-cell-change-flash="true"
          :modules="modules"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import GridManager from '@/components/common/grid-manager'
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  components: {
    GridManager
  },
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      gridId: 'enduser-shipping',
      isDownloading: false,
      gridApi: null,
      modules: AllModules,
      gridOptions: {
        enableBrowserTooltips: true,
        columnDefs: [
          { headerName: this.$t('global.name'), field: 'name', filter: 'agTextColumnFilter', sortable: true, width: 280, sort: 'asc' },
          { headerName: this.$t('global.country'), field: 'country', cellRenderer: this.flagRender, filter: 'agTextColumnFilter', sortable: true, width: 120 },
          { headerName: this.$t('global.city'), field: 'city', filter: 'agTextColumnFilter', sortable: true, width: 180 },
          { headerName: this.$t('global.category'), field: 'regionCategory', filter: 'agTextColumnFilter', sortable: true, width: 100 },
          { headerTooltip: this.$t('tooltips.shipto'), headerName: this.$t('global.ship_to_code'), field: 'aggregate.concat.partnerReference', filter: 'agTextColumnFilter', sortable: true, width: 170 },
          { headerTooltip: this.$t('tooltips.enduser_shipped_quantity'), headerName: this.$t('customer.shipping.grid.shipped_crates'), field: 'shippings.quantity', filter: 'agNumberColumnFilter', sortable: true, width: 130 }
        ],
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          resizable: true
        }
      },
      isFetching: false,
      endusers: null,
      selectedMonth: this.$dayjs().startOf('month').toDate()
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      selectedCustomer: state => state.selectedCustomer
    }),
    selectedMonthISO: function () {
      return this.$dayjs(this.selectedMonth).format('YYYY-MM-DD')
    },
    filteredEndusers: function () {
      return this.endusers ? this.endusers.filter(e => e.shippings?.quantity) : null
    },
    total: function () {
      return this.endusers ? this.endusers.reduce((acc, item) => {
        return acc + (item.shippings?.quantity || 0)
      }, 0) : 0
    }
  },
  watch: {
    selectedCustomer (newValue, oldValue) {
      this.fetchShippings()
    }
  },
  methods: {
    onGridReady: async function (params) {
      this.gridApi = this.gridOptions.api

      this.fetchShippings(this.selectedMonth)
    },
    flagRender: function (params) {
      if (params.data) {
        const flag = `<img src="https://flagcdn.com/w20/${params.data.countryCode.toLowerCase()}.png" style="margin-left: 2px;margin-bottom: -4px;margin-right: 4px;">`
        return flag + ' ' + params.value
      } else {
        return params.value
      }
    },
    submit: async function () {
      this.isSubmitting = true
      await this.fetchShippings()
      this.isSubmitting = false
    },
    fetchShippings: async function (date) {
      this.isFetching = true
      let columns = []
      for (let index = 1; index <= 12; index++) {
        const field = 'shippings.monthAgo' + index
        const label = this.$dayjs(this.selectedMonth).subtract(index, 'months').format('MMM YY')
        columns.push(
          { headerName: label, field: field, filter: 'agNumberColumnFilter', sortable: true, width: 100 }
        )
      }

      if (this.gridApi) { this.gridApi.setColumnDefs([...this.gridOptions.columnDefs, ...columns]) }

      const query = gql`
        query endusersByCustomerId ($customerId: ID!, $toDate: Date, $group: Boolean) {
          endusersByCustomerId (customerId: $customerId, group: $group) {
            totalCount
            edges {
              node {
                aggregate {
                  concat {
                    partnerReference
                  }
                }
                id
                name
                country
                countryCode
                city
                postalCode
                address
                regionCategory
                fromCustomerId
                contractualRentalDays
                maxDaysLose
                usageDays
                expectedTransitTime
                shippings (toDate: $toDate){
                  quantity
                  monthAgo1
                  monthAgo2
                  monthAgo3
                  monthAgo4
                  monthAgo5
                  monthAgo6
                  monthAgo7
                  monthAgo8
                  monthAgo9
                  monthAgo10
                  monthAgo11
                  monthAgo12
                }
              }
            }
          }
        }
        `
      const result = await this.$apollo.query({
        fetchPolicy: 'no-cache',
        query: query,
        variables: {
          customerId: this.$store.state.selectedCustomer,
          toDate: this.selectedMonthISO,
          group: true
        }
      })
      const endusersResult = result.data.endusersByCustomerId
      this.endusers = endusersResult.edges.map(e => e.node)
      this.isFetching = false
    }
  }
}
</script>

<style>
.my-overflow {
  overflow: initial !important;
}
</style>
