<template>
  <div id="lateral-menu">
    <router-link to="/">
      <img class="image" src="@/assets/gps_logo.png" width="270px">
    </router-link>
    <hr class="is-marginless">
    <b-menu :activable="false">
      <!-- For customer -->
      <b-menu-list v-if="user.group.type === 'CUSTOMER'" :label="$t('sidebar.customer')">
        <b-menu-item v-if="authorize('/dashboard/:id')">
          <template slot="label" slot-scope="props">
            <b-icon icon="chart-bar" type="is-blue" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.dashboard') }}</span>
            <b-icon class="is-pulled-right" :icon="props.expanded ? 'caret-down' : 'caret-up'"/>
          </template>

          <b-menu-item v-for="(item, index) in allDashboardsName" :key="index" tag="router-link" :to="{ name: 'dashboard', params: { id: index }}">
            <template slot="label">
              <span class="has-text-grey">{{ item.name.replaceAll("_", " ") }}</span>
            </template>
          </b-menu-item>
        </b-menu-item>

        <b-menu-item v-if="authorize('/maps')" tag="router-link" to="/maps">
          <template slot="label">
            <b-icon icon="map-marker-alt" type="is-red" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.map') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/iotMap')" tag="router-link" to="/iotMap">
          <template slot="label">
            <b-icon icon="map-marker-alt" type="is-blue" size="is-small"/>
            <span class="has-text-grey"> Admin IoT Map </span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/crateTrack')" tag="router-link" to="/crateTrack">
          <template slot="label">
            <b-icon icon="map-marker-alt" type="is-red" size="is-small"/>
            <span class="has-text-grey"> Track crates</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/deliveries')" tag="router-link" to="/deliveries">
          <template slot="label">
            <b-icon icon="truck-moving" type="is-brown" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.deliveries') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/stock')" tag="router-link" to="/stock">
          <template slot="label">
            <b-icon icon="cube" type="is-green" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.stock') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/shipping')" tag="router-link" to="/shipping">
          <template slot="label">
            <b-icon icon="truck" type="is-purple" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.shipping') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/collections')" tag="router-link" to="/collections">
          <template slot="label">
            <b-icon icon="truck-loading" type="is-orange" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.collections') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/download')" tag="router-link" to="/download">
          <template slot="label">
            <b-icon icon="download" type="is-pink" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.download') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/shippingManagement')" tag="router-link" to="/shippingManagement">
          <template slot="label">
            <b-icon icon="dolly-flatbed" type="is-yellow" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.shipping_management') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/scan-users')" tag="router-link" to="/scan-users">
          <template slot="label">
            <b-icon icon="portrait" type="is-info" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.scan_users') }}</span>
          </template>
        </b-menu-item>
      </b-menu-list>
      <!-- For enduser -->
      <b-menu-list v-if="user.group.type === 'END USER'" :label="$t('sidebar.consignee')">
        <b-menu-item v-if="authorize('/enduser/pickup-management')" tag="router-link" to="/enduser/pickup-management">
          <template slot="label">
            <b-icon icon="envelope" type="is-purple" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.pickup_management') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/enduser/stock')" tag="router-link" to="/enduser/stock">
          <template slot="label">
            <b-icon icon="cube" type="is-green" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.stock') }}</span>
          </template>
        </b-menu-item>
        <b-menu-item v-if="authorize('/enduser/collections')" tag="router-link" to="/enduser/collections">
          <template slot="label">
            <b-icon icon="truck-loading" type="is-orange" size="is-small"/>
            <span class="has-text-grey">{{ $t('sidebar.collections') }}</span>
          </template>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Powerbi from '@/services/v2/powerbi'

export default {
  data () {
    return {
      allDashboardsName: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  mounted: async function () {
    const res = await Powerbi.getPowerBIReports()
    const tmp = res.value
    for (let i = 0; this.$store.state.auth.user.group.powerBis.length !== i; i++) {
      for (let it = 0; tmp.length !== it; it++) {
        if (this.$store.state.auth.user.group.powerBis[i].id === tmp[it].id) {
          this.allDashboardsName.push(tmp[it])
        }
      }
    }
  },
  methods: {
    authorize: function (to) {
      const found = this.$router.resolve(to)
      if (!found) return false
      const { authorize } = found.route.meta
      if (authorize) {
        if (!this.user) return false

        const rights = this.user?.role.rights.map(e => e.name)
        return authorize.length && authorize.some(r => rights.includes(r))
      }
      return true
    }
  }

}
</script>
<style>
#lateral-menu li a.is-active span{
  color: white !important;
}
</style>
