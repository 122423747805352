import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'
import constructQueryString from '@/helpers/constructQueryString'

const baseURL = process.env.VUE_APP_API_URL_NEW + '/iotMap'

const iotMap = axios.create({
  baseURL
})

interceptorsSetup(iotMap)

export default class IotMap {
  static async getAllDevices () {
    const result = await iotMap.get('/')
    return result
  }

  static async getOneDevice (device, query) {
    const queryString = constructQueryString(query)
    const { data: result } = await iotMap.get(`/${device}?${queryString}`)
    return result
  }

  static async getSiByCustomer ({ customerId }) {
    let result

    if (customerId) {
      result = await iotMap.get(`/si?customerId=${customerId}`)
    } else {
      result = await iotMap.get(`/si`)
    }
    return result.data
  }

  static async getOne (barcode, query) {
    const queryString = constructQueryString(query)
    const { data: result } = await iotMap.get(`/barcode/${barcode}?${queryString}`)
    return result
  }
}
