import { required, confirmed, length, email, alpha_num as alphaNum, numeric } from 'vee-validate/dist/rules'
import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'

extend('required', required)

extend('email', email)

extend('confirmed', confirmed)

extend('length', length)

extend('maxInput', value => {
  if (value <= 50000) {
    return true
  }

  return 'max: 50 000'
})

extend('minInput', value => {
  if (value >= 0) {
    return true
  }

  return 'min: 0'
})

extend('alphaNum', alphaNum)

extend('alphaNum', alphaNum)

extend('email', email)

extend('numeric', numeric)

// Install messages
localize({
  en
})
